<template>
  <b-overlay :show="isLoading" rounded="lg">
  <div>
    <b-modal v-model="modalDeliver" title="Deliver Process">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="d-flex">
              Are you sure want to process this {{ doData.sapDo }} DO?
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100 d-flex flex-column align-items-center">
          <div class="text-left">
            <span>Create 4 digit PIN <span style="color:red">(*)</span></span>
          </div>
          <b-input
            v-model="pin"
            disabled
            type="number"
            :formatter="maxlength"
            label="Create Pin"
            placeholder="PIN"
            class="mb-2"
            required
          ></b-input>
          <b-button variant="success" @click="updateDeliver">Confirm</b-button>
        </div>
      </template>
    </b-modal>
    <b-modal v-model="modalBulk" title="Bulk Unscan and Delete DO Process">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="d-flex">
              Are you sure want to process bulk unscan and delete this DO?
            </div>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center align-items-center">
          <b-button variant="success" class="ml-1" @click="bulkUnscan"
            >Confirm</b-button
          >
        </div>
      </template>
    </b-modal>
    <b-breadcrumb style="margin-bottom:20px">
      <b-breadcrumb-item to="/">
        <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
      </b-breadcrumb-item>
      <b-breadcrumb-item
        to="/delivery-orders/daikin"
        v-if="goBack === 'do-daikin'"
        >List Delivery Order Daikin
      </b-breadcrumb-item>
      <b-breadcrumb-item
        to="/delivery-orders/sumitomo"
        v-if="goBack === 'do-sumitomo'"
        >List Delivery Order Sumitomo
      </b-breadcrumb-item>
      <b-breadcrumb-item to="/self-collect/daikin" v-if="goBack === 'sc-daikin'"
        >List Self Collect Daikin</b-breadcrumb-item
      >
      <b-breadcrumb-item
        to="/self-collect/sumitomo"
        v-if="goBack === 'sc-sumitomo'"
        >List Self Collect Sumitomo</b-breadcrumb-item
      >
      <b-breadcrumb-item to="/shipping" v-if="goBack === 'shipping'"
        >List Shipping</b-breadcrumb-item
      >
      <b-breadcrumb-item
        to="/return-delivery/daikin"
        v-if="goBack === 'return-daikin'"
        >List Return Delivery Daikin</b-breadcrumb-item
      >
      <b-breadcrumb-item
        to="/return-delivery/sumitomo"
        v-if="goBack === 'return-sumitomo'"
        >List Return Delivery Sumitomo</b-breadcrumb-item
      >
      <b-breadcrumb-item to="/retrigger-api" v-if="goBack === 'resend'"
        >List Retrigger API</b-breadcrumb-item
      >
      <b-breadcrumb-item
        to="/redelivery/daikin"
        v-if="goBack === 'redelivery-daikin'"
        >List Redelivery Daikin</b-breadcrumb-item
      >
      <b-breadcrumb-item
        to="/redelivery/sumitomo"
        v-if="goBack === 'redelivery-sumitomo'"
        >List Redelivery Sumitomo</b-breadcrumb-item
      >
      <b-breadcrumb-item
        to="/mass-do-update"
        v-if="goBack === 'mass-do-update'"
        >List Mass DO Update</b-breadcrumb-item
      >
      <b-breadcrumb-item active
        >Detail Delivery Order -
        {{ doData.status ? doData.status : "New" }}</b-breadcrumb-item
      >
    </b-breadcrumb>

    <b-card no-body>
      <!-- Header conditional-->
      <b-card-header v-if="pageone">
        <!-- 1. Common information this DO-->
        <h4 v-if="goBack === 'shipping'">Shipping</h4>
        <h4 v-if="goBack === 'resend'">Retrigger API</h4>
        <h4
          v-if="
            doData.shippingPoint === '1001' &&
              (goBack === 'do-daikin' ||
                goBack === 'sc-daikin' ||
                goBack === 'return-daikin')
          "
        >
          Daikin Warehouse - {{ doData.status ? doData.status : "New" }}
        </h4>
        <h4
          v-if="
            doData.shippingPoint === '1003' &&
              (goBack === 'do-sumitomo' ||
                goBack === 'sc-sumitomo' ||
                goBack === 'return-sumitomo')
          "
        >
          Sumitomo Warehouse - {{ doData.status ? doData.status : "New" }}
        </h4>
      </b-card-header>

      <b-card-header v-if="pagetwo">
        <!-- 2. List Item information this DO-->
        <h4 v-if="goBack === 'shipping'">Shipping Item List</h4>
        <h4 v-if="goBack === 'resend'">Retrigger API Item List</h4>
        <h4
          v-if="
            doData.shippingPoint === '1001' &&
              (goBack === 'do-daikin' ||
                goBack === 'sc-daikin' ||
                goBack === 'return-daikin')
          "
        >
          Daikin Warehouse Item List -
          {{ doData.status ? doData.status : "New" }}
        </h4>
        <h4
          v-if="
            doData.shippingPoint === '1003' &&
              (goBack === 'do-sumitomo' ||
                goBack === 'sc-sumitomo' ||
                goBack === 'return-sumitomo')
          "
        >
          Sumitomo Warehouse Item List -
          {{ doData.status ? doData.status : "New" }}
        </h4>
      </b-card-header>

      <b-card-header v-if="pagethree">
        <!-- 3. Detail List Item Scanned information this DO-->
        <h4 v-if="goBack === 'shipping'">Shipping Item Detail</h4>
        <h4 v-if="goBack === 'resend'">Retrigger API Item Detail</h4>
        <h4
          v-if="
            doData.shippingPoint === '1001' &&
              (goBack === 'do-daikin' ||
                goBack === 'sc-daikin' ||
                goBack === 'return-daikin')
          "
        >
          Daikin Warehouse Item Detail -
          {{ doData.status ? doData.status : "New" }}
        </h4>
        <h4
          v-if="
            doData.shippingPoint === '1003' &&
              (goBack === 'do-sumitomo' ||
                goBack === 'sc-sumitomo' ||
                goBack === 'return-sumitomo')
          "
        >
          Sumitomo Warehouse Item Detail -
          {{ doData.status ? doData.status : "New" }}
        </h4>
      </b-card-header>
      <!-- End of Header conditional-->
      <b-card-body> 
        <b-col cols="12">
          <b-row>
            <b-col md="3" cols="12" class="mb-2">
              <b-button
                :disabled="isLoading"
                size="md"
                variant="primary"
                @click="$router.go(-1)"
                v-if="goBack !== 'other'"
                ><feather-icon size="16" icon="HomeIcon" /> Main Menu</b-button
              >
              <b-button
                size="md"
                variant="primary"
                @click="$router.go(-1)"
                v-if="goBack === 'other'"
                >Back</b-button
              >
            </b-col>
            
            
            <!--  <b-col cols="3" class="mb-2 hide-on-mobile">
              <b-button
                v-show="
                  goBack == 'return-daikin' || goBack === 'return-sumitomo'
                "
                variant="secondary"
                @click="sendReturnToFTP()"
                ><feather-icon size="16" icon="PrinterIcon" /> Send Return to
                FTP</b-button
              >
            </b-col>
            -->
          </b-row>
        </b-col>

        <!-- Separate one by one page on this document-->
        <b-col cols="12" class="mt-2" v-if="pageone">
          <!-- 1. Common information this DO-->
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>SAP DO Number</label>
                <p class="font-weight-bold">{{ doData.sapDo }}</p>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>SAP SO Number</label>
                <p class="font-weight-bold">{{ doData.sapSo }}</p>
              </div>
            </b-col>
            <b-col cols="12" v-show="isReturn(doData.status)">
              <div class="d-flex flex-column">
                <label>Return Reason</label>
                <p class="font-weight-bold">{{ doData.reason }}</p>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>DO Status</label>
                <p class="font-weight-bold">{{ doData.status }}</p>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Delivery Date</label>
                <p class="font-weight-bold">{{ dates(doData.deliveryDate) }}</p>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Delivery Time</label>
                <p class="font-weight-bold">{{ showTime(doData.priority) }}</p>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Invoice Number</label>
                <p class="font-weight-bold">{{ doData.sapInvoice }}</p>
              </div>
            </b-col>

            <b-col cols="12" v-if="goBack === 'resend'">
              <div class="d-flex flex-column">
                <label>Error Status </label>
                <p class="font-weight-bold" style="color:red">
                  {{ doData.errorMessage }}
                </p>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Delivery Address</label>
                <p class="font-weight-bold">
                  {{ doData.deliveryAddress1 }}
                </p>
                <p class="font-weight-bold">
                  {{ doData.deliveryAddress2 }}
                </p>
                <p class="font-weight-bold">
                  {{ doData.deliveryAddress3 }}
                </p>
                <p class="font-weight-bold">{{ doData.deliveryCity }}</p>
                <p class="font-weight-bold">{{ doData.deliveryPostalCode }}</p>
              </div>
            </b-col>

            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Business Partner</label>
                <p class="font-weight-bold">
                  {{ doData.CustomerName1 + " " + doData.CustomerName2 }}
                </p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Customer Information</label>
                <p class="font-weight-bold">{{ doData.CustomerName3 }}</p>
                <p class="font-weight-bold">{{ doData.phone }}</p>
              </div>
            </b-col>
            <b-col cols="12" v-if="finishedTimestampAvailable(doData)">
                <div class="d-flex flex-column">
                  <label>Fulfillment Time</label>
                  <p class="font-weight-bold">{{ datesWithSecond(doData.timeStampCompleteItem) }}</p>
                </div>
              </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Customer PO</label>
                <p class="font-weight-bold">
                  {{ doData.customerPo.includes('-') && doData.customerPo.startsWith('RSP') ? doData.customerPo.substring(12) : doData.customerPo }}
                </p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>RSP Order Number</label>
                <p class="font-weight-bold">
                  {{ doData.customerPo.includes('-') && doData.customerPo.startsWith('RSP') ? doData.customerPo.substring(0, 11) : '' }}
                </p>
              </div>
            </b-col>

            <b-col
              md="12"
              v-if="doData.customerCollect !== undefined"
              class="mb-2"
            >
              <div class="d-flex flex-column">
                <label>Customer Collected Information</label>
                <b-row>
                  <b-col md="4" cols="12">
                    <p class="font-weight-bold">ID Number</p>
                  </b-col>
                  <b-col md="8" cols="12">
                    <p class="font-weight-bold">
                      {{ doData.customerCollect.idNumber }}
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4" cols="12">
                    <p class="font-weight-bold">Name</p>
                  </b-col>
                  <b-col md="8" cols="12">
                    <p class="font-weight-bold">
                      {{ doData.customerCollect.name }}
                    </p>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            
            <b-col md="2" cols="12" class="mb-2">
              <b-button
                block
                :disabled="isLoading || delay"
                v-show="goBack.includes('shipping')||goBack.includes('sc')||(goBack.includes('return') && doData.status== 'Return Completed')"
                variant="secondary"
                @click="printDO()"
                ><feather-icon size="16" icon="PrinterIcon" /> Print DO</b-button
              >
            </b-col>
            <b-col md="3" cols="12" class="mb-2">
              <b-button
                block
                :disabled="isLoading || delay"
                v-show="goBack.includes('shipping')||goBack.includes('sc')"
                variant="secondary"
                @click="printWarrantyInstruction()"
                ><feather-icon size="16" icon="PrinterIcon" /> Print Warranty Instruction</b-button
              >
            </b-col>
            <b-col cols="12">
    
            </b-col>
            <b-col md="2" cols="12" class="mb-2">
              <b-button
                block
                :disabled="isLoading"
                size="md"
                variant="primary"
                @click="toPage()"
                v-if="goBack !== 'other'"
              >
                <feather-icon size="16" />
                Back to Previous
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="mb-2"
              v-if="checkDeliver()"
            >
                
              <b-button block size="md" :disabled="isLoading" variant="success" @click="toDeliver()">
                Deliver
              </b-button>
            </b-col>
            <b-col cols="12" md="3" class="mb-2" v-else>
              <b-button block size="md" @click="nextPage" variant="primary"
                >Next</b-button
              >
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" class="mt-2" v-if="pagetwo">
          <!-- 2. List Item information this DO-->
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>SAP DO Number</label>
                <p class="font-weight-bold">{{ doData.sapDo }}</p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>DO Status</label>
                <p class="font-weight-bold">{{ doData.status }}</p>
              </div>
            </b-col>
            <b-col cols="12">
              <b-row>
                <strong>Final Detail Item List </strong>
              </b-row>
              <b-row>
                <b-table
                  :fields="scanFields"
                  :items="doData.DO_details"
                  :per-page="perPage"
                  :current-page="currentPage"
                  show-empty
                  responsive
                  :stacked="stackedStatus"
                >
                  <template #cell(actions)="row">
                    <b-button
                      @click="selectItem(row, 'Common')"
                      variant="primary"
                      >Select</b-button
                    >
                  </template>

                  <template #cell(material)="row">
                    {{ getItem(row.item.material).itemModel }}
                  </template>
                  <!-- If qtyUpdate is not undefined, show qtyUpdate-->
                  <!-- qtyUpdate is new qty after update-->

                  <template #cell(desc)="row">
                    {{ getItem(row.item.material).description }}
                  </template>

                  <template #cell(qty)="row">
                    {{ row.item.qtyUpdate ? row.item.qtyUpdate : row.item.qty }}
                  </template>
                  <template #cell(scan)="row">
                    {{ getSummary(row.item.material).scanQty }}
                  </template>
                </b-table>
              </b-row>
              <b-row class="paging-center-mobile">
                <b-pagination
                  :total-rows="doData.DO_details.length"
                  :per-page="perPage"
                  v-model="currentPage"
                />
              </b-row>
            </b-col>
            <!--should recheck for detail return delivery-->
            <b-col
              cols="12"
              v-if="
                doData.mark === 'Return Delivery' ||
                  doData.status === 'Returned'
              "
            >
              <b-row>
                <strong>Final Detail Return Item List : </strong>
              </b-row>
              <b-row>
                <b-table
                  :fields="returnFields"
                  :items="doData.DO_details"
                  :per-page="perPage"
                  :current-page="currentPage"
                  show-empty
                  responsive
                  :stacked="stackedStatus"
                >
                  <template #cell(actions)="row">
                    <b-button
                      @click="selectItem(row, 'Return')"
                      variant="primary"
                      >Select</b-button
                    >
                  </template>

                  <template #cell(material)="row">
                    {{ getItem(row.item.material).itemModel }}
                  </template>

                  <template #cell(desc)="row">
                    {{ getItem(row.item.material).description }}
                  </template>

                  <template #cell(return)="row">
                    {{ row.item.returnBarcode.length }}
                  </template>

                  <template #cell(qtyOngoing)="row">
                    {{ forOngoingQty(row.item) }}
                  </template>

                  <template #cell(qtyReturned)="row">
                    {{ forReturnedQty(row.item) }}
                  </template>
                </b-table>
              </b-row>
              <b-row class="paging-center-mobile">
                <b-pagination
                  :total-rows="doData.DO_details.length"
                  :per-page="perPage"
                  v-model="currentPage"
                />
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2" class="hide-on-mobile">
              <b-button 
              class="w-100 d-flex justify-content-center" 
              @click="backPage" 
              variant="primary">
                Back
              </b-button>
            </b-col>
            <b-col md="2" class="hide-on-mobile">
              <b-button
                v-if="checkBulk(doData) && permission.bulk"
                variant="primary"
                @click="toBulkUnscan(doData)"
                class="w-100 d-flex justify-content-center">
                Bulk Unscan
              </b-button>
            </b-col>
            <b-col cols="6" md="2" class="hide-on-desktop">
              <b-button 
              class="w-100 d-flex justify-content-center" 
              @click="backPage" 
              variant="primary">
                Back
              </b-button>
            </b-col>
            <b-col cols="6" md="2" class="hide-on-desktop">
              <b-button
                v-if="checkBulk(doData) && permission.bulk"
                variant="primary"
                @click="toBulkUnscan(doData)"
                class="w-100 d-flex justify-content-center">
                Bulk Unscan
              </b-button>
            </b-col>
            <b-col cols="4"> </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" class="mt-2" v-if="pagethree">
          <!-- 3. Detail List Item Scanned information this DO-->
          <b-row>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>SAP DO Number</label>
                <p class="font-weight-bold">{{ doData.sapDo }}</p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Model No</label>
                <p class="font-weight-bold">
                  {{ getItem(selectScan.material).itemModel }}
                </p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>Description</label>
                <p class="font-weight-bold">
                  {{ getItem(selectScan.material).description }}
                </p>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex flex-column">
                <label>DO QTY</label>
                <p class="font-weight-bold">
                  {{
                    selectScan.qtyUpdate ? selectScan.qtyUpdate : selectScan.qty
                  }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" v-if="selectScan.qtyUpdate == undefined">
              <div class="d-flex flex-column">
                <label>Return DO QTY</label>
                <p class="font-weight-bold">
                  {{ selectScan.scannedBarcode.length }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" v-if="showCommon">
              <b-row>
                <strong>Final Detail List Item : </strong>
              </b-row>
              <b-row>
                <b-table :fields="itemFields" :items="listItemScan" show-empty>
                  <template #cell(barcode)="row">
                    {{ row.item.scannedBarcode }}
                  </template>
                </b-table>
              </b-row>
            </b-col>
            <b-col cols="12" v-if="showReturn">
              <b-row>
                <strong>Final Detail List Return Item : </strong>
              </b-row>
              <b-row>
                <b-table
                  :fields="itemFields"
                  :items="listItemReturn"
                  show-empty
                >
                  <template #cell(barcode)="row">
                    <span
                      v-if="
                        row.item.skip === true && row.item.returnBarcode !== ''
                      "
                      >Skip Scan</span
                    >
                    <span v-if="row.item.skip === false">{{
                      row.item.returnBarcode
                    }}</span>
                  </template>
                </b-table>
              </b-row>
            </b-col>
            <b-col cols="8">
              <b-button @click="backPageTwo" variant="primary">Back</b-button>
            </b-col>
            <b-col cols="4"> </b-col>
          </b-row>
        </b-col>
        <!-- End of Separate one by one page on this document-->
      </b-card-body>
    </b-card>
  </div>
</b-overlay>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import {
  downloadByPDFMake,
  dateFormat,
  dateFormatFull,
  userAccess,
  sleep
} from "@/utils/utils.js";
import { logo, footer,header } from "./daikin-img-base64.js";
import QRCode from "qrcode";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake/build/pdfmake";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: ["id", "from"],
  data() {
    return {
      // Logo and header
      daikinQR: require('@/assets/images/drcc/qr-code-ewarranty.png'),
      UATdaikinQR: require('@/assets/images/drcc/qr-uat-ewarranty.png'),
      daikinHeader: require('@/assets/images/drcc/Daikin_Header.png'),
      //to deliver
      modalDeliver: false,
      pin: "",

      stackedStatus: "md",
      perPage: 10,
      currentPage: 1,
      //variabel for save detail DO
      doData: {},
      //for disable print button
      delay: true,

      //variabel for save movement data item at scan function
      scanItemTables: [],
      scanFields: [
        { key: "material", label: "Item Model" },
        { key: "desc", label: "Description" },
        { key: "qty", label: "Qty" },
        { key: "scan", label: "Scan" },
        { key: "actions", label: "Action" },
      ],
      returnFields: [
        { key: "material", label: "Item Model" },
        { key: "desc", label: "Description" },
        { key: "return", label: "Return" },
        { key: "qtyOngoing", label: "Ongoing Returned Qty" },
        { key: "qtyReturned", label: "Returned Qty" },
        { key: "actions", label: "Action" },
      ],
      itemFields: [{ key: "barcode", label: "Barcode" }],

      //initiate active page 1 to 3
      pageone: true,
      pagetwo: false,
      pagethree: false,
      isLoading: false,

      showCommon: false, //ini buat bandingin detail kalo dibuka dari selain return delivery
      showReturn: false, //ini buat bandingin detail kalo dibuka dari return delivery

      postal: [],
      itemCatalog: [],

      //to bulk unscan
      doneUnscan: false ,
      selectData: "", //this one variable for save item info after scan function
      selectScan: "", //this one variable for save item info after select item to prepare scanning
      modalBulk: false,

      goBack: "", //this one variable to mark this page is opened from what table list?
      goBackStatus: "All",
      
    };
  },
  created() {
    this.goBack = this.$route.params.from;
    this.goBackStatus = this.$route.params.status;
    this.$store
      .dispatch("delivery/detailDelivery", {
        id: this.id,
      })
      .then((Detail) => {
         console.log(Detail)
        let tableIndex = 0;
        //ini tuh buat list scan Item per barcode baik yang scan atau yang retur
        this.scanItemTables = Detail.DO_details.reduce((prev, next) => {
          let expandedQty = [];
          var barcodeDO = next.scannedBarcode.map((x) => {
            return x.itemUsed;
            }); //ini map semuanya list scan barcode
          var returnBarcode = next.returnBarcode.map((x) => {
            return x.item;
          }); // ini map semuanya list returnBarcode
          for (let i = 0; i < next.qty; i++) {
            var tempBarcode, tempUnScan;

            //for original scan Barcode
            if (barcodeDO[i] === undefined) {
              tempBarcode = "";
            } else {
              tempBarcode = barcodeDO[i];
            }

            //for list return barcode
            if (returnBarcode[i] === undefined) {
              tempUnScan = "";
            } else {
              tempUnScan = returnBarcode[i];
            }
            expandedQty.push({
              ...next,
              scannedBarcode: tempBarcode,
              returnBarcode: tempUnScan,
              qty: 1,
              index: tableIndex,
            });
            tableIndex++;
          }
          
          return [...prev, ...expandedQty];
        }, []);
        
        // ini mapping data delivery detail
        if (
          this.doData.status === "Cancel Delivery"
        ) {
          this.doData = { ...Detail, status: "Delivery Canceled" };
        } else {
          this.doData = Detail;
        }

        // if(this.doData.status === "Scanning Completed"){
        //     let a = {...this.doData, status : "Bayu Ganteng"}
        //     console.log('a',a)
        // }
        
        this.getItems();
      })
      .catch((err) => {
        console.error( err );
      });
      
    //temporary get api items catalog from axios
    /*
    axios
      .get("sales-and-purchase/drcc/items?total=100")
      .then((response) => {
        this.itemCatalog = response.data.data;
        //console.log("item.catalog", this.itemCatalog);
      })
      .catch((e) => {
        console.log(e);
      });
    */
    //temporary get api post code from axios
    // axios.get('sales-and-purchase/drcc/postal').then((response) => {
    //     this.postal = response.data.data;
    // }).catch((e) => {
    //     console.log(e)
    // })
  },
  methods: {
    ...mapActions({
      updateDelivery: "delivery/updateDO",
      testBulkUnscan: "delivery/bulkUnscan",
    }),
    checkDeliver() {
      if( !this.goBack.includes('shipping')){
        return false;
      }

      if (this.doData.status !== "Scanning Completed" || !this.permission.deliver) {
        return false;
      }
      return true;
    },
    toDeliver() {
      //this.modalDeliver = true;
      //console.info('this.doData.sapSo ',this.doData.sapSo)
      this.pin = this.doData.sapSo.slice(-4);
      this.isLoading = true;
      this.updateDeliver();
    },
    async updateDeliver() {
      if (this.pin.length !== 4) {
        this.$bvToast.toast("Please Create a 4-digit PIN.", {
          title: "Warning",
          variant: "danger",
          solid: true,
        });
        return (this.modalDeliver = true);
      }
      if (
        this.doData.mark === "Edit Delivery" ||
        this.doData.mark === "Cancel Delivery"
      ) {
        this.$bvToast.toast("There is Edit / Cancel DO of this order", {
          title: "Warning",
          variant: "danger",
          solid: true,
        });
      } else {
        //update status to in progress
        //var data = { ...this.doData };
        //data.status = "Delivery in Progress";
        //data.pin = this.pin; // add pin to deliveryData

        this.updateDelivery({ idDelivery: this.doData._id, deliveryData: {
          status: "Delivery in Progress",
          pin: this.pin
        } })
          .then((res) => {
            this.isLoading = false;

            //this.$store.dispatch("delivery/getDeliveryOrder");
            this.$bvToast.toast("Delivery In Progress", {
              title: "Success",
              variant: "success",
              solid: true,
            });
            // resets pin
            this.pin = "";
            var sapCall = !res.apiResult
              ? { type: "N" }
              : !res.apiResult.apiResult
              ? { type: "N" }
              : res.apiResult.apiResult;
            //console.log('sap call ',sapCall);
            //console.log('api result ',res.apiResult)
            if (sapCall.type == "E") {
              this.$bvToast.toast(
                "Fail to send notification to SAP. Please inform administrator",
                {
                  title: "Failed",
                  variant: "danger",
                  solid: true,
                }
              );
            }
            this.modalDeliver = false;
            this.goBackStatus = 'Delivery in Progress'
            this.toPage()

            // this.doData.status="Delivery in Progress";
          })
          .catch((e) => {
            this.isLoading = false;
            console.log(e);
            if (e.response.status === 400) {
              this.$bvToast.toast(e.response.data.message, {
                title: "Failed",
                variant: "danger",
                solid: true,
              });
            } else {
              this.$bvToast.toast(
                "Error found when putting DO to Delivery, please try again",
                {
                  title: "Failed",
                  variant: "danger",
                  solid: true,
                }
              );
            }
          })
          .finally(() => {
            this.isLoading = false;
            //this.$store.dispatch("delivery/getDeliveryOrder");
          });
      }
    },
    toPage() {
      console.info("toPage ", this.goBack);
      var statusQuery = this.goBackStatus;
      if (this.goBack === "resend") {
        this.$router.push({
          path: "/retrigger-api",
          query: { status: statusQuery,sapDo:this.doData.sapDo },
        });
      } else if (this.goBack === "redelivery-daikin") {
        this.$router.push({
          path: "/redelivery/daikin",
          query: { status: statusQuery,sapDo:this.doData.sapDo },
        });
      } else if (this.goBack === "redelivery-sumitomo") {
        this.$router.push({
          path: "/redelivery/sumitomo",
          query: { status: statusQuery,sapDo:this.doData.sapDo },
        });
      } else if (this.goBack === "return-daikin") {
        this.$router.push({
          path: "/return-delivery/daikin",
          query: { status: statusQuery,sapDo:this.doData.sapDo },
        });
      } else if (this.goBack === "return-sumitomo") {
        this.$router.push({
          path: "/return-delivery/sumitomo",
          query: { status: statusQuery,sapDo:this.doData.sapDo },
        });
      } else if (this.goBack === "do-daikin") {
        this.$router.push({
          path: "/delivery-orders/daikin",
          query: { status: statusQuery,sapDo:this.doData.sapDo },
        });
      } else if (this.goBack === "do-sumitomo") {
        this.$router.push({
          path: "/delivery-orders/sumitomo",
          query: { status: statusQuery,sapDo:this.doData.sapDo },
        });
      } else if (this.goBack === "sc-daikin") {
        this.$router.push({
          path: "/self-collect/daikin",
          query: { status: statusQuery,sapDo:this.doData.sapDo },
        });
      } else if (this.goBack === "sc-sumitomo") {
        this.$router.push({
          path: "/self-collect/sumitomo",
          query: { status: statusQuery,sapDo:this.doData.sapDo },
        });
      } else if (this.goBack === "shipping-daikin") {
        this.$router.push({
          path: "/shipping/daikin",
          query: { status: statusQuery,sapDo:this.doData.sapDo },
        });
      } else if (this.goBack === "shipping-sumitomo") {
        this.$router.push({
          path: "/shipping/sumitomo",
          query: { status: statusQuery,sapDo:this.doData.sapDo },
        });
      } else if (this.goBack === "manual-return-daikin") {
        this.$router.push({
          path: "/manual-return-delivery/daikin",
          query: { status: statusQuery,sapDo:this.doData.sapDo },
        });
      } else if (this.goBack === "manual-return-sumitomo") {
        this.$router.push({
          path: "/manual-return-delivery/sumitomo",
          query: { status: statusQuery,sapDo:this.doData.sapDo },
        });
      }else if (this.goBack === "mass-do-update") {
        this.$router.push({
          path: "/mass-do-update",
        });
      } else {
        this.$router.push(`/`);
        this.$bvToast.toast("Shipping Point Not Found", {
          title: "Warning",
          variant: "danger",
          solid: true,
        });
      }
    },
    getItems() {
      var idList = this.doData.DO_details.map((x) => {
        return x.material;
      });
      //temporary get api items catalog from axios
      var list = idList.join("|");
      console.info("getItems list ", list);
      axios
        .get("sales-and-purchase/drcc/items/multi?idList=" + list)
        .then((response) => {
          this.itemCatalog = response.data.data;
          this.delay = false
          // console.log(this.itemCatalog)
        })
        .catch((e) => {
          this.$bvToast.toast("Load data failed, please reload the page.", {
          title: "Warning",
          variant: "danger",
          solid: true,
          });
          console.log(e);
        });
    },
    isReturn(status) {
      let wantedStatus = [
        "Returned",
        "Waiting Acknowledgment",
        "Waiting Approval",
        "Approved",
        "Return Completed",
      ];
      var isReturn = wantedStatus.includes(status);
      console.info("is return ", isReturn);
      return isReturn;
    },
    dates(date) {
      return dateFormat(date);
    },
    datesWithSecond(date) {
      if (!date || isNaN(new Date(date).getTime())) {
        return "";
      }
      return dateFormatFull(date);
    },
    //ini tuh ketentuan waktu pengiriman delivery
    async printLayout() {
      //console.log("this do data ", this.doData);

      /**
        Get Dealer Data
      **/

      var dealer = await this.$store.dispatch("dealer/getDealerWithQuery", {
        dealerName: this.doData.dealerNo,
      });
      var dealerName = dealer.data.length > 0 ? dealer.data[0].dealerName : "";

      //Looping for DO Detail
      //Mapping DO Detail
      var itemList = this.doData.DO_details
      .map((x) => {
        var itemModelCheck = this.getItem(x.material).itemModel;
        if (!itemModelCheck || itemModelCheck == ' ' || itemModelCheck == undefined || itemModelCheck == '') return;
        return {
          model: itemModelCheck,
          qty: (x.qtyUpdate != undefined) ? x.qtyUpdate : x.qty,
          empty: "",
          barcode: (x.skip) ? "Skip Scan" : x.scannedBarcode
            .map((x) => {
              return x.itemUsed;
            })
            .join("   "),
          remainedQty : x.scannedBarcode.length,
          returnedQty : x.returnBarcode.length,
          remainedBarcode : x.scannedBarcode.length === 0 ? "No item Remained" :( (x.skip) ? "Skip Scan" : x.scannedBarcode.map((x) => {
              return x.itemUsed;
            })
            .join("   ")),
          returnedBarcode : x.returnBarcode.length === 0 ? "No item Returned" : ((x.skip) ? "Skip Scan" : x.returnBarcode.map((x) => {
            return x.item;
          })
          .join("   ")),
        };
      }).filter(item => item && item.qty !== 0 && (!item.qtyUpdate || item.qtyUpdate !== 0)); // Tambahkan filter untuk memeriksa qty

      //listAllScanned function is not required

      //itemList.barcode = this.listAllScanned(itemList.model);
      //console.log("barcodes ", itemList.barcode);

      var baseURL = config.RSP_URL || "https://rsp.daikin.com.sg"

      // Generate the QR code as a base64-encoded image
      const qrCode = await QRCode.toDataURL(
        baseURL+"/pages/login-do/" + this.doData.sapDo
      );
      /*
      const toDataURL = (url) =>
        fetch(url)
          .then((response) => response.blob())
          .then(
            (blob) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              })
          );
      let header64;
      await toDataURL(this.daikinHeader).then((res) => {
        header64 = res;
      });
      */

      if (pdfMake.vfs == undefined) {
        // var pdfFonts = require("./customVfs.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }

      const tableData = {
        type: "table",
        data: {
          widths: ["25%", "10%", "52%", "13%"],
          header: ["Model", "Quantity", "Barcode", "Return QTY"],
          dataTable: itemList,
          valueTable: ["model", "qty", "barcode", "empty"],
          noBorder: false,
        },
      };
      const remainedItemTable = {
        type: "table",
        data: {
          widths: ["25%", "10%", "65%"],
          header: ["Model", "Quantity", "Barcode"],
          dataTable: itemList,
          valueTable: ["model", "remainedQty", "remainedBarcode"],
          noBorder: false,
        },
      };
      const returnedItemTable = {
        type: "table",
        data: {
          widths: ["25%", "10%", "65%"],
          header: ["Model", "Quantity", "Barcode"],
          dataTable: itemList,
          valueTable: ["model", "returnedQty", "returnedBarcode"],
          noBorder: false,
        },
      };
      const qrTableData ={
        type: "table",
        data:{
          widths:["15%","35%","15%","35%"],
          header:["Return","Quantity:","Redelivery","Date:\t\t\t\t\t\tPostal:\t\t\t\t\t\t\t"],
          dataTable:[],
          noBorder:false
        }
      }
      let customerData

      if(this.doData.customerCollect && (this.doData.status == "Fulfilled" || this.doData.status == "Collected") ){
        customerData = [
          {
            text: "Delivery Order",
            value: this.doData.sapDo,
          },
          {
            text: "Delivery Date",
            value: this.dates(this.doData.deliveryDate),
            //},{
            //  text:'Delivery Time',
            //  value:this.showTime(this.doData.priority)
          },
          {
            text: "Sales Order",
            value: this.doData.sapSo,
            boldValue: true,
          },
          {
            text: "Purchase Order",
            value: this.doData.customerPo.includes('-') && this.doData.customerPo.startsWith('RSP') ? this.doData.customerPo.substring(12) : this.doData.customerPo,
          },
          {
            text: "RSP Order Number",
            value: this.doData.customerPo.includes('-') && this.doData.customerPo.startsWith('RSP') ? this.doData.customerPo.substring(0, 11) : "",
          },
          {
            text: "Business Partner",
            value:
              this.doData.CustomerName1 + "" + this.doData.CustomerName2 + "",
          },
          {
            text: "Dealer Code",
            value: this.doData.dealerNo,
          },
          {
            text: "Customer Name",
            value: this.doData.CustomerName3,
          },
          {
            text: "Phone",
            value: this.doData.phone,
          },
          {
            text: "Address",
            value: this.doData.deliveryAddress1,
          },
          {
            text: null,
            value: this.doData.deliveryAddress2,
          },
          {
            text: null,
            value: this.doData.deliveryAddress3,
          },
          {
            text: null,
            value:
              this.doData.deliveryCity + " " + this.doData.deliveryPostalCode,
          },
          {
            text: "Collected Information",
            value: null
          },
          {
            text: "ID Number",
            value: this.doData.customerCollect.idNumber
          },
          {
            text: "Name",
            value: this.doData.customerCollect.name
          },
        ];
      }else{
        customerData = [
          {
            text: "Delivery Order",
            value: this.doData.sapDo,
          },
          {
            text: "Delivery Date",
            value: this.dates(this.doData.deliveryDate),
            //},{
            //  text:'Delivery Time',
            //  value:this.showTime(this.doData.priority)
          },
          {
            text: "Sales Order",
            value: this.doData.sapSo,
            boldValue: true,
          },
          {
            text: "Purchase Order",
            value: this.doData.customerPo.includes('-') && this.doData.customerPo.startsWith('RSP') ? this.doData.customerPo.substring(12) : this.doData.customerPo,
          },
          {
            text: "RSP Order Number",
            value: this.doData.customerPo.includes('-') && this.doData.customerPo.startsWith('RSP') ? this.doData.customerPo.substring(0, 11) : "",
          },
          {
            text: "Business Partner",
            value:
              this.doData.CustomerName1 + "" + this.doData.CustomerName2 + "",
          },
          {
            text: "Dealer Code",
            value: this.doData.dealerNo,
          },
          {
            text: "Customer Name",
            value: this.doData.CustomerName3,
          },
          {
            text: "Phone",
            value: this.doData.phone,
          },
          {
            text: "Address",
            value: this.doData.deliveryAddress1,
          },
          {
            text: null,
            value: this.doData.deliveryAddress2,
          },
          {
            text: null,
            value: this.doData.deliveryAddress3,
          },
          {
            text: null,
            value:
              this.doData.deliveryCity + " " + this.doData.deliveryPostalCode,
          },
        ];
      }

      //console.info('header ',header64);
      //console.info('footer ',footer)

      let docDefinition = {
        pageMargins: [70, 120, 70, 70],
        header: function(currentPage, pageCount, pageSize) {
          return [
            {
              image: `${header}`,
              alignment: "center",
              width: 520,
              margin: [0, 50, 0, 0],
            },
          ];
        },
        footer: function(currentPage, pageCount) {
          return [
            {
              image: `${footer}`,
              alignment: "center",
              width: 600,
              margin: [0, 0, 0, 50],
            },
          ];
        },

        content: [
          {
            layout: "noBorders",
            table: {
              widths: ["*", "auto"],
              body: [
                [
                  {
                    // alignment: "center",
                    // width: "auto",
                    image: qrCode,
                    fit: [100, 100],
                  },
                  {
                    text: `\n\n\nScan this QR Code to accept/return the delivery:\n\n`+
                    `- To accept all items, choose "Accept Delivery"\n`+
                    `- To return one or more items, choose "Delivery Returned"*\n`+
                    `- To reschedule delivery of all items, choose "Re-delivery"*\n\n`+
                    `* Additional transport charges will be imposed.\n\n`,
                    //`Return\t\tQuantity:\n`+
                    //`Redelivery\tDate:\t\t\tPostal:\n`,
                    fontSize: 10,
                    margin: [0, -22, 0, 0],
                  },
                ],
              ],
              border: [],
            },
            margin: [0, 10, 0, 10],
          },

          { text: `Delivery Detail`, bold: true },

          { text: `\n` },

          {
            layout: "noBorders",
            table: {
              widths: ["30%", "1% ", "69%"],
              headerRows: 1,
              body: [
                ...customerData.map((customer) => {
                  if (customer.text == null) {
                    return [
                      { text: "", fontSize: 10 },
                      { text: "", fontSize: 10 },
                      { text: customer.value, fontSize: 10 },
                    ];
                  } else if(customer.value == null){
                    return [
                      { text: customer.text, fontSize: 10, bold: true },
                      { text: "", fontSize: 10 },
                      { text: "", fontSize: 10 },
                    ];
                  }
                  return [
                    { text: customer.text, fontSize: 10 },
                    { text: ":", fontSize: 10 },
                    {
                      text: customer.value,
                      fontSize: 10,
                      bold: customer.boldValue,
                    },
                  ];
                }),
              ],
              border: [],
            },
            margin: [0, 10, 0, 10],
          },

          /*

          {text:`Delivery Order     : ${this.doData.sapDo}`,fontSize:10,},
          {text:`Delivery Date      : ${this.dates(this.doData.deliveryDate)}`,fontSize:10,},
          {text:`Delivery Time      : ${this.showTime(this.doData.priority)}`,fontSize:10,},
          {text:`Sales Order         : ${this.doData.sapSo}`,fontSize:10,},
          {text:`Purchase Order  : ${this.doData.customerPo}`,fontSize:10,},
          {text:`Dealer Code        : ${this.doData.dealerNo}`,fontSize:10,},
          {text:`Name                  : ${this.doData.CustomerName1} ${this.doData.CustomerName2} ${this.doData.CustomerName3} ${this.doData.CustomerName4}`,fontSize:10,},
          {text:`Address              : ${this.doData.deliveryAddress1}, ${this.doData.deliveryPostalCode}`,fontSize:10,},
          {text:`Phone                 : ${this.doData.phone}`,fontSize:10,},
          **/
          { text: `\n` },

          { text: this.doData.status == "Return Completed" ?`Item Remained`: "Item Detail", bold: true },

          { text: `\n` },

          {
            table: this.doData.status == "Return Completed" ?{
              widths: remainedItemTable.data.widths,
              headerRows: 1,
              body: [
              remainedItemTable.data.header.map((header) => ({
                  text: header,
                  fontSize: 10,
                })), // Table header
                ...remainedItemTable.data.dataTable.map((item) =>
                remainedItemTable.data.valueTable.map((key) => ({
                    text: item[key] + "   ",
                    fontSize: 10,
                  }))
                ), // Dynamic rows
              ],
              ...(remainedItemTable.data.noBorder && { style: "noBorders" }), // Optional: Remove table borders
            } : {
              widths: tableData.data.widths,
              headerRows: 1,
              body: [
                tableData.data.header.map((header) => ({
                  text: header,
                  fontSize: 10,
                })), // Table header
                ...tableData.data.dataTable.map((item) =>
                  tableData.data.valueTable.map((key) => ({
                    text: item[key] + "   ",
                    fontSize: 10,
                  }))
                ), // Dynamic rows
              ],
              ...(tableData.data.noBorder && { style: "noBorders" }), // Optional: Remove table borders
            },
          },
          // {
          //   type: "table",
          //   data: {
          //     widths: ["25%", "15%", "60%"],
          //     header: ["Model", "Quantity", "Barcode"],
          //     dataTable: itemList,
          //     valueTable: [`model`, `qty`, `barcode`],
          //     noBorder: false,
          //   },
          // },

          /**
          {
            type:'text',
            data:`${this.user.props.firstName} ${this.user.props.lastName}, `
          },
          */
        ],
      };

      // Check if status is "Return Completed" and add the additional table
      if (this.doData.status == "Return Completed") {
        docDefinition.content.push(
          { text: `\n` },
          { text: `Item Returned`, bold: true },
          { text: `\n` },
          {
            table: {
              widths: returnedItemTable.data.widths,
              headerRows: 1,
              body: [
              returnedItemTable.data.header.map((header) => ({
                  text: header,
                  fontSize: 10,
                })), // Table header
                ...returnedItemTable.data.dataTable.map((item) =>
                returnedItemTable.data.valueTable.map((key) => ({
                    text: item[key] + "   ",
                    fontSize: 10,
                  }))
                ), // Dynamic rows
              ],
              ...(returnedItemTable.data.noBorder && { style: "noBorders" }), // Optional: Remove table borders
            },
          }
        );
      }

      return docDefinition;
    },
    //untuk print DO
    async printDO() {
      this.isLoading=true
      var docDefinition = await this.printLayout().catch((err) => {
        console.log("err", err);
        this.isLoading=false
        this.$bvToast.toast(
            "Load data failed, please reload the page",
            {
              title: "Failed",
              variant: "danger",
              solid: true,
            }
          );
        return 
      })

      let fileName = `DO-${this.doData.sapDo}`;
      if(docDefinition){
        pdfMake.createPdf(docDefinition).download(fileName);
        this.isLoading=false
      }
    },
    // untuk print warranty
    async printWarrantyInstruction() {

      var baseURL = config.EWARRANTY_URL || "https://ewarranty.daikin.com.sg"

      
      this.isLoading = true
      const toDataURL = (url) =>
        fetch(url)
          .then((response) => response.blob())
          .then(
            (blob) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              })
          );
      let qrcode = (config.IS_UAT==undefined)?this.daikinQR:this.UATdaikinQR;
      let qrcode64;
      await toDataURL(qrcode).then((res) => {
        qrcode64 = res;
      });
      let getData = {};

      let inventoryData = await this.$store.dispatch("inventory/getAllInventories", {
        sapDo: this.doData.sapDo,
        entry: "10000"
      })

      let inventoryArray = inventoryData.data
      let pdfContent = []
      let docDefinition = {content : []}
      for (let i = 0; i < inventoryArray.length; i++) {
        let model = inventoryArray[i].itemModel;
        let serial = inventoryArray[i].serial;
        let barcode = inventoryArray[i].barcode;
        let dealerCode = inventoryArray[i].dealerCode;
        let DONumber = inventoryArray[i].sapDo;
        let distChan = inventoryArray[i].industryType ? inventoryArray[i].industryType : '';
        let skip = inventoryArray[i].skip

        let listChannel = ['DL', 'DR', 'DS', 'M1', 'M2', 'M3'];
        let type = listChannel.includes(distChan) ? 'Route' : 'Project';

        // ... buat objek docDefinition sesuai dengan data saat ini ...
        // var pdfMake = require("pdfmake/build/pdfmake.js");
        if (pdfMake.vfs == undefined) {
          // var pdfFonts = require("./customVfs.js");
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        let date = new Date().toISOString().substring(0, 10);

        function dateTransform(date) {
          return new Date(date).toUTCString().substring(5, 16);
        }
        if(skip !== true){

          if(i != inventoryArray.length - 1 ){
            pdfContent.push( 
            { text: `WARRANTY REGISTRATION CARD \n`, color:'#0ABDFF' },
            { text: `valid in Singapore only \n`, color:'#0ABDFF', fontSize:10 },  
            { text: `\n` },
            {columns:[
              { text: `Register online at ${baseURL}\nGo Paperless, Build a Greener Future with Daikin\n` },
              {image: `${qrcode64}`,
              width:85,
              height:85,
              absolutePosition: { x: 580, y: 145 }
                // margin:[120,0,0,0],
                // fit:[75,75]
                }
            ]},
              
            { text: `\n` },
            { text: `\n` },
            {columns:[
              { text: `Model No: ${model}\n\nSerial No: ${serial}\n\nBarcode No: ${barcode}` },
              { text: `Dealer: ${dealerCode}\n\nWarranty Type: ${type}\n\nDO No: ${DONumber}` }
            ]},
              
  
            { text: `\n` },
            { text: `\n` },
  
            { text: `Important: \n`, bold:true },
            { text: `Please register within 14 days from installation date. \n` },
            { text: `A digital warranty certificate will be generated upon successful registration. \n` },
            { text: `You can view your certificate through  ${baseURL}/viewwarranty\n` },
            { text: `For any warranty enquiry, please call us at 65838888 ext 2196\n` },
  
            { text: `\n` },
  
            {
              text: '                   Thank you for choosing Daikin, the World’s Leading Air conditioningManufacturer                ',
              color:'#ffffff',
              background:'#0AAEFF',
              alignment:'center',
              
            },
            { text: '', pageBreak: 'after' } // Menambahkan page break di akhir konten ini
            )
          }
  
          if ( i == inventoryArray.length - 1){
            pdfContent.push( 
            { text: `WARRANTY REGISTRATION CARD \n`, color:'#0ABDFF' },
            { text: `valid in Singapore only \n`, color:'#0ABDFF', fontSize:10 },  
            { text: `\n` },
            {columns:[
              { text: `Register online at ${baseURL} \nGo Paperless, Build a Greener Future with Daikin\n` },
              {image: `${qrcode64}`,
              width:85,
              height:85,
              absolutePosition: { x: 580, y: 145 }
                // margin:[120,0,0,0],
                // fit:[75,75]
                }
            ]},
              
            { text: `\n` },
            { text: `\n` },
            {columns:[
              { text: `Model No: ${model}\n\nSerial No: ${serial}\n\nBarcode No: ${barcode}` },
              { text: `Dealer: ${dealerCode}\n\nWarranty Type: ${type}\n\nDO No: ${DONumber}` }
            ]},
              
  
            { text: `\n` },
            { text: `\n` },
  
            { text: `Important: \n`, bold:true },
            { text: `Please register within 14 days from installation date. \n` },
            { text: `A digital warranty certificate will be generated upon successful registration. \n` },
            { text: `You can view your certificate through ${baseURL}/viewwarranty\n` },
            { text: `For any warranty enquiry, please call us at 65838888 ext 2196\n` },
  
            { text: `\n` },
  
            {
              text: '                   Thank you for choosing Daikin, the World’s Leading Air conditioning Manufacturer                ',
              color:'#ffffff',
              background:'#0AAEFF',
              alignment:'center',
              
            },
          )
          }
        }
        

        // Tambahkan konten item ke dokumen dengan pageBreak
        docDefinition.content.push(pdfContent);
      }
      // optimal width header 500
      docDefinition = {
        pageOrientation: 'landscape', // Mengatur orientasi halaman ke landscape
        pageMargins: [125, 150, 125, 50],
        header: function(currentPage, pageCount, pageSize) {
          return [
            {
              image: `${header}`,
              alignment: "center",
              width: 700,
              margin: [0, 50, 0, 0],
            },
          ];
        },
        content: pdfContent,
        defaultStyle: {
        fontSize: 13,
        }
      };
      // Buat PDF untuk item saat ini
      pdfMake
      .createPdf(docDefinition)
      .download(`Daikin Warranty Registration Card.pdf`);

      this.isLoading = false

    },

    sendReturnToFTP() {
      this.$store
        .dispatch("delivery/sendReturnToFTP", this.doData._id)
        .then((Detail) => {
          this.$bvToast.toast(
            "DO Return :" + this.doData.sapDo + " sent to FTP",
            {
              title: "Success",
              variant: "success",
              solid: true,
            }
          );
        })
        .catch((err) => {
          console.log({ err });
        });
    },

    listAllScanned(material) {
      var scanned = this.scanItemTables.filter((x) => {
        return x.material === material && x.scannedBarcode !== "";
      });
      var returned = this.scanItemTables.filter((x) => {
        return x.material === material && x.returnBarcode !== "";
      });
      return {
        scanned: scanned,
        returned: returned,
      };
    },
    showTime(priority) {
      var time;
      if (priority === "11") {
        time = "Morning: 9AM - 12PM";
      } else if (priority === "12") {
        time = "Afternoon: 1PM - 3PM";
      } else if (priority === "13") {
        time = "Afternoon: 3PM - 6PM";
      } else if (priority === "03") {
        time = "Self Collect AMK";
      } else if (priority === "05") {
        time = "Self Collect Sumitomo";
      } else {
        time = "";
      }
      return time;
    },
    //ini waktu klik button "Select" yang ada di halaman kedua
    selectItem(row, type) {
      this.selectScan = row.item;
      this.pagetwo = false;
      this.pagethree = true;
      //ngebedain data item list delivery seharusnya
      if (type === "Common") {
        this.showCommon = true;
      }
      //ini ngebedain data item list kalau ada permintaan retur
      if (type === "Return") {
        this.showReturn = true;
      }
    },
    getSummary(material) {
      //ini buat cari jumlah discan dan belum discan berdasarkan material id yg dicari
      let itemSummary = this.summary[material];
      if(itemSummary==undefined){
        console.info('item summary is undefined ',material);
        itemSummary={scanQty:0}
      }
      return itemSummary;
    },
    finishedTimestampAvailable(doData){
     return doData.timeStampCompleteItem && !isNaN(new Date(doData.timeStampCompleteItem).getTime()) 
      
    },
    nextPage() {
      //dari page 1 ke page 2
      this.pageone = false;
      this.pagetwo = true;
    },
    backPage() {
      //dari page 2 ke page 1
      this.pageone = true;
      this.pagetwo = false;
    },
    backPageTwo() {
      //dari page 3 ke page 2
      this.pagetwo = true;
      this.pagethree = false;
      //clear both show table detail item list
      this.showCommon = false;
      this.showReturn = false;
    },
    //ini buat cek informasi detail material yg ada di DO_details ke item katalog
    getItem(data) {
      var searchItem = this.itemCatalog.find((x) => {
        return x._id == data;
      });
      var result;
      if (searchItem === undefined) {
        result = {
          itemModel: " ",
        };
      } else {
        result = searchItem;
      }
      return result;
    },
    toBulkUnscan(data) {
      this.modalBulk = true;
      this.selectData = data;
    },
    bulkUnscan() {
      this.modalBulk = false;
      var data = this.selectData;
      this.testBulkUnscan({ idDelivery: data._id, deliveryData: data })
        .then(async () => {
          this.doneUnscan = true
          //this.$store.dispatch("delivery/getDeliveryOrder");

          this.$bvToast.toast("Bulk Unscan Successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          },);
          await sleep(1000);
          this.toPage()
        })
        .catch((e) => {
          if (e.response.data !== undefined) {
            this.$bvToast.toast(e.response.data.message, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }else{
            this.$bvToast.toast("Connection error. Please refresh the page and try again", {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }
        })
    },
    checkBulk(row) {
      var result = false;
      if (row.status === "Cancel Delivery" && !this.doneUnscan) {
        result = true;
      }
      if (row.mark === "Cancel Delivery" && !this.doneUnscan) {
        result = true;
      }
      return result;
    },
    forOngoingQty(item){
      let ongoingQty = 0
      item.returnBarcode.forEach(returned => {
        // Jika item ditemukan di scannedBarcode, tambahkan ke ongoingQty
        let foundInScanned = item.scannedBarcode.some(scanned => scanned.itemUsed === returned.item);
        if (foundInScanned) {
          ongoingQty++;
        }
      });
      return ongoingQty
    },
    forReturnedQty(item){
      let returnedQty = 0
      // Periksa setiap item di returnedBarcode
      item.returnBarcode.forEach(returned => {
        // Jika item tidak ditemukan di scannedBarcode, tambahkan ke returnedQty
        let notFoundInScanned = !item.scannedBarcode.some(scanned => scanned.itemUsed === returned.item);
        if (notFoundInScanned) {
          returnedQty++;
        }
      });
      return returnedQty
    },
  },
  computed: {
    permission() {
      var isDaikin= this.goBack.includes("daikin")
      var result;
      if(isDaikin && this.goBack != 'do-daikin'){
        result= userAccess("Shipping Daikin", "shipping_menu");
      }
      if(!isDaikin && this.goBack != 'do-sumitomo'){
        result= userAccess("Shipping Sumitomo", "shipping_menu");
      }
      if(this.goBack == 'do-daikin'){
        result= userAccess("Delivery Orders Daikin",
        "delivery_order_menu");
      }
      if(this.goBack == 'do-sumitomo'){
        result= userAccess("Delivery Orders Sumitomo",
        "delivery_order_menu");
      }
      
      // console.log("Shipping",result)
      return result;
    },
    //for disable button print do
    disabled(){

    },
    summary() {
      //ini tuh buat itung ada berapa yang udah di scan atau belum
      return this.scanItemTables.reduce((prev, next) => {
        if (prev[next.material] === undefined) {
          prev[next.material] = { scanQty: 0, emptyQty: 0 };
        }

        if (next.scannedBarcode === "") {
          prev[next.material].emptyQty += 1;
        } else {
          prev[next.material].scanQty += 1;
        }
        return prev;
      }, {});
    },
    //mapping list scanned  berdasarkan material

    //mapping list detail scanned barcode yg butuh ditampilkan di page 3
    listItemScan() {
      var result = this.scanItemTables.filter((x) => {
        return (
          x.material === this.selectScan.material && x.scannedBarcode !== ""
        );
      });
      return result;
    },
    //mapping list detail permintaan retur barcode yg butuh ditampilkan di page 3
    listItemReturn() {
      var result = this.scanItemTables.filter((x) => {
        return (
          x.material === this.selectScan.material && x.returnBarcode !== ""
        );
      });
      return result;
    },
  },
};
</script>

<style scoped>
/* For mobile phones: */
@media (max-width: 872px) {
  .adjust-on-mobile {
    margin-bottom: 10px !important;
  }
  .paging-center-mobile {
    justify-content: center !important;
  }
  .hide-on-mobile {
    display: none !important;
  }
}



@media (min-width: 872px) {
  .hide-on-desktop {
    display: none !important;
  }
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(112, 107, 107, 0.5); /* Latar belakang semi transparan */
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
